import React from 'react';
import { I18nextContext } from "gatsby-plugin-react-i18next";

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Shop from '../components/Shop';

const ShopPage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);

  return (
    <Layout page='shop' locale={language}>
      <SEO title="Shop" />
      <Shop locale={language}/>
    </Layout>
  );
};

export default ShopPage;
