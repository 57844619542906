import styled from 'styled-components';
import tw from 'twin.macro';

export const ProductWrap = styled.ul`
  ${tw`flex flex-col h-full`}
`;

export const Grid = styled.div`
  ${tw`grid gap-3 xl:gap-6 place-content-center`}
  @media screen and (min-width: 1441px) {
    ${tw`grid-cols-4`}
  }
  @media screen and (min-width: 1281px) and (max-width: 1440px){
    ${tw`grid-cols-3`}
  }
  @media screen and (max-width: 1280px) {
    ${tw`grid-cols-4`}
  }
  @media screen and (max-width: 991px) {
    ${tw`grid-cols-3`}
  }
  @media screen and (max-width: 580px) {
    ${tw`grid-cols-2`}
  }
`;

export const KeyWrap = styled.div`
  ${tw`bg-white flex flex-col h-full`}
  .wrapProduct{
    border: 2px solid #F3F3F3;
    border-radius: 4px;
    ${tw`h-full flex flex-col justify-between`}
    :hover{
      border-radius: 4px;
      ${tw`cursor-pointer border-2 border-black`}
    }
  }
  img{
    ${tw`w-full object-center lg:w-auto lg:h-full mx-auto`}
  }
  @media (max-width: 1640px) {
    ${tw`w-full`};
  }
`;

export const ImageWrap = styled.div`
  height: 265px;
  padding: 16px 10.37px 0;
  ${tw`w-full overflow-hidden group-hover:opacity-75`}
  img{
    ${tw`h-full object-center object-contain`}
  }

  @media screen and (min-width: 768px) {
    width: auto;
    height: 206px;
    margin: 0 auto;
  }
  @media screen and (max-width: 414px) {
    width: 170px;
    height: 170px;
    margin: 0 auto;
  }
  @media screen and (max-width: 375px) {
    width: 153px;
    height: 153px;
  }
  @media screen and (max-width: 320px) {
    width: 125px;
    height: 125px;
  }
`;

export const WrapDetails = styled.div`
  ${tw`mt-4 px-2 flex justify-between`}
  h3{
    ${tw`text-base text-brown-basic tracking-wide`}
  }
  p{
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #4D4545;
    margin: 8px 0px;
  }
  button{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #685858;
    margin: 8px 0 0;
    ${tw`text-left`}
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.03em;
    }
  }
`;

export const AddToCart = styled.div`
  ${tw`px-1 pb-2 sm:pb-2 sm:px-2`}
  .price{
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #4D4545;
    margin: 8px 0 16px 0;


    .product-out-link {
      width: 17px;
      height: 17px;
      float: right;
    }
  }
  .sale{
    width: fit-content;
    ${tw`bg-yellow-300 text-brown-darker px-1.5 py-1 mt-1 font-bold rounded-l-md rounded-tr-md block antialiased`}
  }
`;

export const QtyControl = styled.div`
  ${tw`flex flex-wrap h-10`}
  input{
    ${tw`text-mini`}
  }
  button{
    ${tw`h-5 align-middle text-brown-basic text-center items-center
        font-semibold py-1 px-0 border border-t-gray-200 border-r-gray-200
        border-b-gray-200 border-l-white hover:bg-white hover:text-brown-dark`}
  }
`;

export const Desktop = styled.div`
    ${tw`hidden sm:block sticky pt-4 bg-grayu-semimed`};
`;

export const Mobile = styled.div`
    ${tw`block sm:hidden`};
`;

export const FilterWrap = styled.div`
    ${tw`fixed w-6/12 mx-auto inset-x-0 bottom-16 text-center`};
    z-index: 1;
`;

export const SearchWrap = styled.div`
    ${tw`mt-1 py-1 border-t-2 border-black relative`};
    input{
      ${tw`pl-8`};
      font-size: 13px;
      line-height: 135%;
      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      color: #656B70;
    }
    svg{
      width: 16px; height: 16px;
      ${tw`absolute top-5 left-2.5`};
    }
`;

export const WrapBottomContent = styled.div`
    ${tw`mt-6 mb-4 mx-4`};
    div{
      ${tw`border-0`};
    }
    .btnMobFilter{
      letter-spacing: 0.04em;
      background-color: #F5F5F5;
      ${tw`text-xssemi`};
    }
    .btnFilter{
      letter-spacing: 0.05em;
      ${tw`w-full bg-black text-white text-base py-3 font-semibold`};
    }
`;

export const Counter = styled.p`
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #9A9292;
  margin-bottom: 12px;
`;

export const BtnWrap = styled.div`
    ${tw`w-full`};
    button{
      letter-spacing: 0.04em;
      color: #685858;
      background: #FFFFFF;
      border-radius: 2px;
      border: 2px solid #fff;
      padding: 8px 12px;
      margin-bottom:11px;
      ${tw`ml-1`};
    }
    .active{
        color: #0D090E;
        border: 2px solid #0D090E;
    }
    .clearFilter{
      margin: 0 1rem;
    }
`;

export const MobileTopLabel = styled.div`
    ${tw`w-full`};
    h2{
      letter-spacing: 0.04em;
      color: #2E2E2E;
      ${tw`font-bold text-xl3 my-4`};
    }
    div{
      ${tw`flex justify-between items-center`};
      p{
        letter-spacing: 0.08em;
        color: #9A9292;
        ${tw`text-mini uppercase py-0 m-0`};
        :first-child{
          border: 1px solid #9A9292;
          ${tw`p-1`};
        }
      }
      input {
        width: 70%;
      }
    }

`;

export const Search = styled.div`
    ${tw`w-full`};
    div{
      ${tw`pt-2 relative mx-auto text-gray-600`};
      input{
        ${tw`w-full border border-gray-300 bg-white h-10 px-3 pr-16 rounded-sm text-sm focus:outline-none`};
      }
      button{
        ${tw`absolute right-0 top-0 mt-4 mr-3`};
      }
    }
`;
