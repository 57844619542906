import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { FilterIcon } from '@heroicons/react/outline';
import * as Styled from './styles';

const Filter = ({ type, locale, appliedCategoryFilters, allCategories, clearSearch }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const categories = allCategories;

  function onDismiss() {
    setOpenFilter(false);
  }

  const applyFilters = () => {
    appliedCategoryFilters.next(activeFilters);
    setOpenFilter(false);
  };

  const clickedButton = (category) => {
    if (activeFilters.includes(category.name)) {
      const newFilters = activeFilters.filter((id) => id !== category.name);
      setActiveFilters(newFilters);
    } else {
      const newFilters = [...activeFilters, category.name];
      setActiveFilters(newFilters);
    }
  };

  const filterSelection = (category) => {
    if (activeFilters.includes(category.name)) {
      const newFilters = activeFilters.filter((id) => id !== category.name);
      setActiveFilters(newFilters);
      appliedCategoryFilters.next(newFilters);
    } else {
      const newFilters = [...activeFilters, category.name];
      setActiveFilters(newFilters);
      appliedCategoryFilters.next(newFilters);
    }
  };

  const allProducts = () => {
    setActiveFilters([])
    appliedCategoryFilters.next([])
  };

  const clearFilters = () => {
    setActiveFilters([])
    appliedCategoryFilters.next([])
    clearSearch();
  };

  const localizedCategoryName = (category) => {
    return locale === 'en' ? category.name : category.name_cn;
  };

  return (
    <>
      {type === 'desktop' && (
        <Styled.BtnWrap className='filterCategory'>
          <button
              onClick={() => allProducts()}
              className={`w-auto ${activeFilters.length > 0 ? 'inactive': 'active'}`}
              type="button"
            >
              {locale === 'en' ? 'All Products' : '所有产品'}
            </button>
          {categories.map((category, index) => (
            <button
              key={index}
              onClick={() => filterSelection(category)}
              className={`w-auto ${activeFilters.includes(category.name) ? 'active': 'inactive'}`}
            >
              {localizedCategoryName(category)}
            </button>
          ))}
            <a
              onClick={() => clearFilters()}
              className={`clearFilter w-auto text-black cursor-pointer`}
            >
              {locale === 'en' ? 'Clear All Filters' : '清除所有过滤器'}
            </a>
        </Styled.BtnWrap>
      )}

      {type === 'mobile' && (
        <Styled.FilterWrap>
          <button
            onClick={() => setOpenFilter(true)}
            className="bg-black rounded-full shadow-md border border-grayu-light p-2 inline-flex items-left text-white hover:text-gray-500 hover:bg-gray-100"
          >
            <span className="sr-only">Open menu</span>
            <FilterIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <BottomSheet
            open={openFilter}
            onDismiss={onDismiss}
            snapPoints={({ minHeight }) => minHeight}
            className='z-50'
          >
            <Styled.WrapBottomContent>
              <Styled.Counter>{locale === 'en' ? "Filters" : "过滤器"}</Styled.Counter>
              <Styled.BtnWrap>
                {categories.map((category, index) => (
                  <button
                    key={index}
                    onClick={() => clickedButton(category)}
                    className={`btnMobFilter ${activeFilters.includes(category.name) ? 'active': 'inactive'}`}
                  >
                    {localizedCategoryName(category)}
                  </button>
                ))}
              </Styled.BtnWrap>
              <button className="btnFilter" onClick={applyFilters}>
                Apply Filters
              </button>
            </Styled.WrapBottomContent>
          </BottomSheet>
        </Styled.FilterWrap>
      )}
    </>
  );
};

Filter.propTypes = {
  type: PropTypes.string,
  locale: PropTypes.string,
};

Filter.defaultProps = {
  locale: 'en',
};

export default Filter;
