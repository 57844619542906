import React from 'react';

import Products from 'components/ui/Products';
import Minicart from 'components/Minicart';
import Menu from './../Header/MainNavi/ShopMenuMobile';
import Tablet from './../Header/MainNavi/TabsMenuMobile';

import * as Styled from './styles';

const Shop = ({ locale }) => {
  return (
    <>
      <Styled.Wrap>
        <div className="containner mx-auto">
          <div className="grid grid-cols-12 gap-2 xl:gap-8">
            <div className="products">
              <Products locale={locale} />
            </div>
            <div className="minicart">
              <div className="stickyCart">
                <Minicart locale={locale} />
              </div>
            </div>
          </div>
        </div>
      </Styled.Wrap>

      <div className="tabletShopIcon">
        <Tablet locale={locale} />
      </div>

      <Styled.Mobile>
        <Menu locale={locale} />
      </Styled.Mobile>
    </>
  );
};

export default Shop;
