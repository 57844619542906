import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrap = styled.div`
  ${tw`m-auto pt-4 sm:pt-10`};
  .minicart{
    ${tw`hidden xl:block col-span-3`};
    @media screen and (max-width: 1280px) {
      ${tw`hidden`}
    }
    .stickyCart{
      ${tw`sticky pt-4`}
      top: 0;
    }
  }
  .products{
    ${tw`col-span-9`}
    @media screen and (max-width: 1280px) {
      ${tw`col-span-12`}
    }
  }
`;

export const Grid = styled.div`
  ${tw`w-full flex flex-shrink gap-1 sm:gap-6`};
  margin-top: 13px;
`;

export const Colspan1 = styled.div`
  width:296px;
  ${tw`flex-auto`};
  @media (max-width: 768px) {
    ${tw`hidden`};
  }
`;

export const Colspan3 = styled.div`
  width:952px;
  ${tw`flex-auto`};
  @media (max-width: 768px) {
    ${tw`w-full `};
  }
`;

export const MinicartWrap = styled.div`
  ${tw`sticky top-0 m-auto hidden md:block`};
`;

export const Tab = styled.div`
  ${tw`hidden`};
  @media (min-width: 992px) && (max-width: 1024px){
    ${tw`block`};
  }
`;

export const Mobile = styled.div`
  @media (min-width: 992px) {
    ${tw`hidden`};
  }
  @media (max-width: 991px) {
    ${tw`block`};
  }
`;
