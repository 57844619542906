import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { MenuIcon, ShoppingCartIcon, XIcon } from '@heroicons/react/outline';
import { graphql, useStaticQuery } from 'gatsby';

import * as Styled from './../styles';
import Summary from './../../../Minicart/Summary';
import { I18nextContext, Link, useI18next } from 'gatsby-plugin-react-i18next';

import { selectCart$ } from '../../../../state/cart/cart.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import LogoMini from './../../../../assets/images/ustore-mob.png';
import { triggerAccountModal, triggerPwaInstall } from "../../../../state/global-triggers/global-triggers.service";
import { selectPostcode$ } from "../../../../state/checkout/checkout.service";
import { selectUser$ } from "../../../../state/user/user.service";
import Language from "../../../ui/Language";

const ShopMenuMobile = ({ locale }) => {
  const { navigate } = useI18next();
  const { language } = React.useContext(I18nextContext);
  const [unMount$] = useState(new Subject());
  const [openCart, setOpenCart] = useState(false);
  const [totalUniqueItems, setTotalUniqueItems] = useState(0);
  const [postCode, setPostcode] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    selectCart$.pipe(takeUntil(unMount$)).subscribe((cart) => {
      const uniq = cart.products?.length || 0;
      setTotalUniqueItems(uniq);
    });

    selectUser$.pipe(takeUntil(unMount$)).subscribe((user) => {
      setUser(user);
    });

    selectPostcode$.pipe(takeUntil(unMount$)).subscribe((postcode) => {
      setPostcode(postcode);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  const gql = useStaticQuery(graphql`
    query {
      strapiNavigationMenu_en: strapiNavigationMenu(locale: { eq: "en" }) {
        shop_menu
        contact_us_menu
        get_the_app
        signup_button_label
        login_button_label
        mobile_account_link_text
        mobile_cart_checkout_button_label
        mobile_cart_go_back
        mobile_cart_heading
        mobile_contact_link_text
        mobile_enter_postcode_placeholder
        mobile_faq_link_text
        mobile_home_link_text
        mobile_liquors_link_text
        mobile_privacy_link_text
        mobile_terms_link_text
        mobile_welcome_back_text
      }
      strapiNavigationMenu_zh: strapiNavigationMenu(locale: { eq: "zh" }) {
        shop_menu
        contact_us_menu
        get_the_app
        signup_button_label
        login_button_label
        mobile_account_link_text
        mobile_cart_checkout_button_label
        mobile_cart_go_back
        mobile_cart_heading
        mobile_contact_link_text
        mobile_enter_postcode_placeholder
        mobile_faq_link_text
        mobile_home_link_text
        mobile_liquors_link_text
        mobile_privacy_link_text
        mobile_terms_link_text
        mobile_welcome_back_text
      }
    }
  `);
  const translations = gql[`strapiNavigationMenu_${locale}`];
  return (
    <Popover>
      <div className="fixed bg-gradient-to-t from-white via-white to-transparent p-4 inset-x-0 h-24" style={{bottom: '62px'}}>
        <Popover.Button className="float-left bg-white mt-4 rounded-full shadow-md border border-grayu-light p-2 inline-flex items-left text-grayu-light hover:text-gray-500 hover:bg-gray-100">
          <span className="sr-only">Open menu</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </Popover.Button>

        <button
          onClick={() => setOpenCart(true)}
          className="float-right relative bg-white mt-4 rounded-full shadow-md border border-grayu-light p-2 inline-flex items-left text-grayu-light hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Open menu</span>
          <ShoppingCartIcon className="h-6 w-6" aria-hidden="true" />
          <span className="bg-blue-600 p-1 rounded-full w-6 h-6 absolute -top-2 -right-2 font-medium text-xs text-white group-hover:text-gray-800">
            {totalUniqueItems}
          </span>
        </button>
      </div>

      <Transition.Root show={openCart} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={setOpenCart}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative w-screen max-w-md">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6 text-center">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {translations.mobile_cart_heading}
                      </Dialog.Title>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      <div className="absolute inset-0 px-4 sm:px-6">
                        <div className="h-full" aria-hidden="true">
                          <Summary locale={locale} />
                          <div className="mt-16 text-center h-16">
                            {postCode ?
                              <Link to="/checkout">
                                <button className="w-11/12 text-sm py-3 mb-2 bg-black border border-black text-white hover:bg-transparent">
                                  {translations.mobile_cart_checkout_button_label}
                                </button>
                              </Link>
                              :
                              <Link to="/"><button className='disabledBtn'>Add Postcode to Proceed</button></Link>
                            }
                            <button
                              className="my-4 text-blue-600 text-xs"
                              onClick={() => setOpenCart(false)}
                            >
                              <span className="flex mx-auto">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 19l-7-7 7-7"
                                  />
                                </svg>
                                <label>{translations.mobile_cart_go_back}</label>
                                <br />
                                <br />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition>
        <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={() => {}}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 left-0 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="-translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="-translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative w-screen max-w-md">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="flex gap-2 align-middle text-xl font-medium text-gray-900">
                        <img src={LogoMini} alt="Ubottle" />
                        <b className="pt-2.5">Ubottle</b>
                      </Dialog.Title>
                      <span className="text-brown-vthin text-xssemi tracking-wide font-normal">
                        {translations.mobile_welcome_back_text}
                      </span>

                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0 px-4 sm:px-6">
                        <div className="h-full" aria-hidden="true">
                          <Styled.MobWrap className="h-full">
                            <Styled.NavWrapLinks className="text-left">
                              <Styled.NavLinks to="/">
                                {translations.mobile_home_link_text}
                              </Styled.NavLinks>
                              <Styled.NavLinks to="/shop">
                                {translations.mobile_liquors_link_text}
                              </Styled.NavLinks>
                              <Styled.FakeLinks
                                onClick={() => {
                                  if (user.id) {
                                    triggerAccountModal(new Date().getTime());
                                  } else {
                                    navigate('/login')
                                  }
                                }}
                              >
                                {translations.mobile_account_link_text}
                              </Styled.FakeLinks>
                              <Styled.NavLinks to="/contact">
                                {translations.mobile_contact_link_text}
                              </Styled.NavLinks>

                              <hr className="mt-4"/>

                              <Styled.NavMiniLinks to="/privacy-policy">
                                {translations.mobile_privacy_link_text}
                              </Styled.NavMiniLinks>
                              <Styled.NavMiniLinks to="/terms-and-condition">
                                {translations.mobile_terms_link_text}
                              </Styled.NavMiniLinks>

                              <hr className="mt-4"/>
                              <div className="py-2">
                                <Language lang={language}/>
                              </div>

                            </Styled.NavWrapLinks>
                          </Styled.MobWrap>

                          <div className="absolute inset-x-0 bottom-0 text-center h-16">
                            <button
                              type="button"
                              className="rounded-full p-2 bg-black text-gray-300 hover:text-white"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Popover>
  );
};

ShopMenuMobile.propTypes = {
  locale: PropTypes.string,
};

ShopMenuMobile.defaultProps = {
  locale: 'en',
};

export default ShopMenuMobile;
