import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Modal from 'react-modal';
import { BottomSheet } from 'react-spring-bottom-sheet';
import PropTypes from 'prop-types';
import * as ItemsJs from 'itemsjs';
import MiniSearch from 'minisearch';

import * as Styled from './styles';
import ProductView from './../Modal/ProductView';
import ProductMobileView from './../Modal/Mobile/product';
import AddToCartBtn from './../Button/AddToCart';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, debounceTime, startWith, takeUntil, tap } from 'rxjs/operators';
import { selectPostcode$ } from '../../../state/checkout/checkout.service';
import PostCodeModal from '../../PostcodeModal';
import PostCodes from '../../PostcodeModal';
import Filter from './filter';
import { interpolateTranslation, productImageUrl, searchProducts } from '../../../state/utils';
import SearchBtn from './../icons/search';
import ScrollToTop from '../../utils/ScrollTop';

const Products = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      allRestApiApiStore1Products {
        allProducts: nodes {
          id: endpointId
          name
          name_cn
          description
          description_cn
          online_price_sum
          volume
          volume_unit
          img_path
          productInventoryVariations {
            id
            quantity
            variation_id
            inventory_id
          }
          base_variation {
            id
            img_path
            cost
            in_store_price
            online_price
            rrp
            savings
            uom
            uom_cn
            uom_qty
            inventory_id
            purchase_limit
          }
          inventory {
            id
          }
          variations {
            cost
            created_at
            id
            in_store_price
            inventory_id
            online_price
            rrp
            savings
            uom
            uom_cn
            uom_qty
            img_path
            purchase_limit
            inventory {
              name
              id
            }
          }
          categories {
            id
            name
            name_cn
          }
        }
        totalCount
      }
      strapiShopPage_en: strapiShopPage(locale: { eq: "en" }) {
        show_total_and_filtered_count
        liquors_text
        filter_products
        showing_all
        add_to_cart
      }
      strapiShopPage_zh: strapiShopPage(locale: { eq: "zh" }) {
        show_total_and_filtered_count
        liquors_text
        filter_products
        showing_all
        add_to_cart
      }
    }
  `);

  const translations = gql[`strapiShopPage_${locale}`];
  const totalCount = gql.allRestApiApiStore1Products.totalCount;
  let allCategories = [];
  const productList = gql.allRestApiApiStore1Products.allProducts.filter((product) => {
    return product.name !== 'FB_DUMMY_BUNDLE'
  }).map((product) => {
    return {
      ...product,
      categories_agg: product.categories.map((category) => {
        if (!allCategories.find((C) => C.name === category.name)) {
          allCategories.push(category);
        }

        return category.name;
      }),
    };
  });

  const [unMount$] = useState(new Subject());
  const [postCode, setPostcode] = useState(null);
  const [showPostcodeModal, setShowPostcodeModal] = useState(false);
  const [showProductDesktopModal, setShowProductDesktopModal] = useState(false);
  const [showProductMobileModal, setShowProductMobileModal] = useState(false);
  const [productToShow, setProductToShow] = useState(false);
  const [searchResults, setSearchResults] = useState(productList);
  const [searchValue$] = useState(new Subject(''));
  const [searchValue, setSearchValue] = useState('');
  const [appliedCategoryFilters$] = useState(new Subject([]));
  const [docWidth, setDocWidth] = useState(0);

  // using minisearch on top of itemsjs as this is able to search chinese text
  let miniSearch = new MiniSearch({
    fields: ['name', 'name_cn', 'description'],
  });

  miniSearch.addAll(productList);

  const [searchableProductsNativeSearchDisabled] = useState(
    ItemsJs(productList, {
      native_search_enabled: false,
      aggregations: {
        categories_agg: {
          title: 'Categories',
          size: 100,
          conjunction: false,
        },
      },
    })
  );

  const [searchableProducts] = useState(
    ItemsJs(productList, {
      sortings: {
        name_asc: {
          field: 'name',
          order: 'asc',
        },
      },
      aggregations: {
        categories_agg: {
          title: 'Categories',
          size: 100,
          conjunction: false,
        },
      },
      searchableFields: ['name', 'name_cn'],
    })
  );

  useEffect(() => {
    selectPostcode$.pipe(takeUntil(unMount$)).subscribe((postcode) => {
      setPostcode(postcode);
    });

    combineLatest(
      searchValue$.pipe(startWith(''), takeUntil(unMount$), debounceTime(300)),
      appliedCategoryFilters$.pipe(startWith([]), takeUntil(unMount$))
    )
      .pipe(
        takeUntil(unMount$),
        tap(async ([searchValue, appliedCategoryFilters]) => {
          if (!searchValue && !appliedCategoryFilters.length) {
            setSearchResults(productList);
          } else if (!searchValue && !!appliedCategoryFilters.length) {
            const api_results = await searchProducts('', appliedCategoryFilters).toPromise()
            setSearchResults(productList.filter(p => api_results.includes(p.id)));
          } else if (!!searchValue) {
            const api_results = await searchProducts(searchValue).toPromise()
            setSearchResults(productList.filter(p => api_results.includes(p.id)));
          }
        }),
        catchError((e) => {
          alert('error on search');
          return of(true);
        })
      )
      .subscribe();

    setDocWidth(document.documentElement.clientWidth);

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  const showDesktopModal = (product) => {
    setProductToShow(product);
    setShowProductDesktopModal(true);
  };

  const showMobileModal = (product) => {
    setProductToShow(product);
    setShowProductMobileModal(true);
  };

  const onDismiss = () => {
    setShowProductDesktopModal(false);
    setShowProductMobileModal(false);
  };

  const localizedProductName = (product) => {
    const name = locale === 'en' ? product.name : product.name_cn;

    if (!!product.volume) {
      let volume;
      const arr = product.volume.split('.');
      if (arr[1] && arr[1] === '00') {
        volume = arr[0];
      } else {
        volume = product.volume;
      }
      return `${name} ${volume || ''} ${product.volume_unit || ''}`
    }

    return name;
  };

  return (
    <>
      <Styled.Desktop className="stickyFilter">
        <Styled.Counter>
          {interpolateTranslation(
            translations.show_total_and_filtered_count,
            [`FILTERED_COUNT_TOKEN`, `TOTAL_COUNT_TOKEN`],
            [`${searchResults.length}`, `${totalCount}`]
          )}
        </Styled.Counter>
        <Styled.SearchWrap>
          <input
            type="search"
            onChange={(e) => {
              searchValue$.next(e.target.value);
              setSearchValue(e.target.value);
            }}
            placeholder={locale === 'en' ? 'Start typing to search...' : '搜索产品'}
            value={searchValue}
          />
          <SearchBtn />
        </Styled.SearchWrap>
        <Filter
          type="desktop"
          locale={locale}
          appliedCategoryFilters={appliedCategoryFilters$}
          allCategories={allCategories}
          clearSearch={() => {
            searchValue$.next('');
            setSearchValue('');
          }}
        />
      </Styled.Desktop>

      <Styled.Mobile>
        <Styled.MobileTopLabel>
          <h2>{translations.liquors_text}</h2>
          <div className="flex items-center">
            <input
              type="search"
              onChange={(e) => {
                searchValue$.next(e.target.value);
                setSearchValue(e.target.value);
              }}
              placeholder={locale === 'en' ? 'Start typing to search...' : '搜索产品'}
              value={searchValue}
            />
            <p id="activeFilter">{translations.showing_all}</p>
          </div>
        </Styled.MobileTopLabel>
        <Filter
          type="mobile"
          locale={locale}
          appliedCategoryFilters={appliedCategoryFilters$}
          allCategories={allCategories}
          clearSearch={() => {
            searchValue$.next('');
            setSearchValue('');
          }}
        />
      </Styled.Mobile>

      <Styled.Grid>
        {searchResults.map((product) => {
          const img_path = !product.inventory ? product.img_path : product.base_variation.img_path;

          const price = !product.inventory
            ? product.online_price_sum
            : product.base_variation.online_price;
          return (
            <div key={product.id}>
              <Styled.KeyWrap key={product.id} className={` key`}>
                <div className="wrapProduct">
                  <Styled.ImageWrap>
                    <button
                      onClick={() => showMobileModal(product)}
                      className="viewProductMobile w-full h-full"
                    >
                      <img
                        src={
                          img_path === null
                            ? `https://via.placeholder.com/254x320/fff/333`
                            : productImageUrl(img_path)
                        }
                        alt={localizedProductName(product)}
                        className={`product_${product.id}_img`}
                      />
                    </button>
                    <button
                      onClick={() => showDesktopModal(product)}
                      className="viewProductDesktop w-full h-full"
                    >
                      <img
                        src={
                          img_path === null
                            ? `https://via.placeholder.com/254x320/fff/333`
                            : productImageUrl(img_path)
                        }
                        alt={localizedProductName(product)}
                        className={`product_${product.id}_img`}
                      />
                    </button>
                  </Styled.ImageWrap>
                  <Styled.WrapDetails>
                    <div>
                      <h3>
                        <button
                          onClick={() => showMobileModal(product)}
                          className="viewProductMobile"
                        >
                          {localizedProductName(product)}
                        </button>
                        <button
                          onClick={() => showDesktopModal(product)}
                          className="viewProductDesktop"
                        >
                          <span aria-hidden="true" />
                          {localizedProductName(product)}
                        </button>
                      </h3>
                    </div>
                  </Styled.WrapDetails>
                  <Styled.AddToCart>
                    <div className="w-full">
                      <AddToCartBtn
                        price={price}
                        item={product}
                        label={translations.add_to_cart}
                        hasPostcode={!!postCode}
                        noPostcodeCallback={() => {
                          setShowPostcodeModal(true);
                        }}
                        locale={locale}
                      />
                    </div>
                  </Styled.AddToCart>
                </div>
              </Styled.KeyWrap>
            </div>
          );
        })}

        <ScrollToTop />
      </Styled.Grid>

      {docWidth >= 640 && (
        <Modal
          isOpen={showPostcodeModal}
          onRequestClose={() => setShowPostcodeModal(false)}
          contentLabel="My dialog"
          id="postCodeModal"
        >
          <PostCodeModal
            type="1"
            suggestionSelectedCustomCallback={() => setShowPostcodeModal(false)}
          />
        </Modal>
      )}

      {docWidth < 640 && (
        <BottomSheet
          open={showPostcodeModal}
          scrollLocking={false}
          onDismiss={() => {
            setShowPostcodeModal(false);
          }}
          snapPoints={({ minHeight }) => minHeight}
        >
          <div className="w-full py-4 px-4 z-20 pb-28">
            <PostCodes
              type="1"
              addedCallback={() => {
                setShowPostcodeModal(false);
              }}
            />
          </div>
        </BottomSheet>
      )}

      <Modal
        isOpen={showProductDesktopModal}
        onRequestClose={() => setShowProductDesktopModal(false)}
        contentLabel="My dialog"
        id="viewProductModal"
      >
        <ProductView
          id={productToShow.id}
          img={
            !productToShow.inventory
              ? productToShow.img_path
              : productToShow.base_variation.img_path
          }
          name={localizedProductName(productToShow)}
          description={locale === 'en' ? productToShow.description : productToShow.description_cn}
          price={
            !productToShow.inventory
              ? productToShow.online_price_sum
              : productToShow.base_variation.online_price
          }
          item={productToShow}
          hasPostcode={!!postCode}
          noPostcodeCallback={() => {
            setShowProductDesktopModal(false);
            setShowPostcodeModal(true);
          }}
          addedCallback={() => {
            setShowProductDesktopModal(false);
          }}
          locale={locale}
        />
      </Modal>
      <BottomSheet
        open={showProductMobileModal}
        onDismiss={onDismiss}
        scrollLocking={false}
        snapPoints={({ minHeight }) => minHeight}
      >
        <ProductMobileView
          id={productToShow.id}
          img={
            !productToShow.inventory
              ? productToShow.img_path
              : productToShow.base_variation.img_path
          }
          name={localizedProductName(productToShow)}
          description={locale === 'en' ? productToShow.description : productToShow.description_cn}
          price={
            !productToShow.inventory
              ? productToShow.online_price_sum
              : productToShow.base_variation.online_price
          }
          item={productToShow}
          hasPostcode={!!postCode}
          noPostcodeCallback={() => {
            setShowProductDesktopModal(false);
            setShowProductMobileModal(false);
            setShowPostcodeModal(true);
          }}
          addedCallback={() => {
            setShowProductDesktopModal(false);
            setShowProductMobileModal(false);
          }}
          locale={locale}
        />
      </BottomSheet>
    </>
  );
};

Products.propTypes = {
  locale: PropTypes.string,
};

Products.defaultProps = {
  locale: 'en',
};

export default Products;
