import styled from "styled-components";

export const ScrollToTopWrapper = styled.div`
    right: 0;
    bottom: 70px;
    text-align: right;
    padding: 1rem 1rem;
    position: fixed;
    width: 100px;
`;
